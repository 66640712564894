import css from '../../styles/pages/Auth.module.scss'

const Index = ({children}:{children: JSX.Element | JSX.Element[]}) => {
  return (
    <main className={css.auth}>
      <div className={'uiLogo'} style={{width: '100px', height: '100px', margin: 0}}/>
      {children}
    </main>
  )
}

export default Index
