import React, { useEffect, useState } from 'react';
import Heading from '../../components/Heading';
import {
  BubbleItem,
  getBubbles,
  getUserLinks,
  Loader,
  SiteItem,
  WStore as store,
} from 'bubbles-module';
import classNames from 'classnames';
import Tree from './Tree';
import { useParams } from 'react-router-dom';
import css from 'bubbles-module/styles/Bubbles.module.scss';
import { observer } from 'mobx-react';

const Index = () => {
  const params = useParams();
  const [tree, setTree] = useState(null as any);
  const [activeView, setActiveView] = useState(store.preferredBubblesView as string);
  const [order, setOrder] = useState(store.preferredBubblesOrder as boolean);
  const [sortBy, setSortBy] = useState(store.preferredBubblesSortBy as string);
  const [loaded, setLoaded] = useState(false);

  const load = async () => {
    console.log(activeView);
    if (['rows', 'tiles'].includes(activeView)) {
      await loadLinks();
    }
    if (activeView === 'folder') {
      await fetchSingleUserBubbles();
    }

    if (activeView === 'tree') {
      await loadTree();
    }
  };

  useEffect(() => {
    setLoaded(false);

    if (store.user) {
      load().then(() => {
        setLoaded(true);
      });
    }
  }, [params.username, activeView, order, sortBy, store.user]);

  useEffect(() => {
    store.setPreferredBubblesView(activeView);
  }, [activeView]);

  useEffect(() => {
    store.setPreferredBubblesOrder(order);
  }, [order]);

  useEffect(() => {
    store.setPreferredBubblesSortBy(sortBy);
  }, [sortBy]);

  async function loadLinks() {
    const data = await getUserLinks(
      params?.username || store.user.user_name,
      order ? 'ASC' : 'DESC',
      sortBy
    );
    if (data) {
      data.forEach((link: any) =>
        link.bubbles.forEach((bubble: any) => (bubble.creator = link.creator))
      );
    }
    store.setLinks(data);
  }

  async function fetchSingleUserBubbles() {
    const res = await getBubbles(
      null,
      params?.username || store.user.user_name,
      order ? 'ASC' : 'DESC',
      sortBy
    );
    store.setBubbles(res?.data);
  }

  async function loadTree() {
    const res = (
      await getUserLinks(params?.username || store.user.user_name, order ? 'ASC' : 'DESC', sortBy)
    )?.data;
    res.forEach((link: any) =>
      link.bubbles.forEach((bubble: any) => (bubble.creator = link.creator))
    );

    const treeFolders = [
      ...new Map(
        res.flatMap(({ bubbles }: any) => bubbles).map((bubble: any) => [bubble.id, bubble])
      ).values(),
    ]
      .sort((a: any, b: any) => {
        const dir = order ? 1 : -1;
        if (sortBy === 'title') {
          return (
            dir *
            a.attributes.tag
              .replace(/\p{Emoji_Presentation} /gu, '')
              .toLowerCase()
              .localeCompare(
                b.attributes.tag.replace(/\p{Emoji_Presentation} /gu, '').toLowerCase()
              )
          );
        }
        return -dir * a.modified_at.localeCompare(b.modified_at);
      })
      .map((bubble: any) => bubble.attributes.tag);

    const parsedTree = Object.fromEntries(
      treeFolders.map((tag: any) => {
        return [
          tag,
          res.filter(({ bubbles }: any) =>
            bubbles.find(({ attributes: { tag: t } }: any) => t === tag)
          ),
        ];
      })
    );
    setTree(parsedTree);
  }

  return (
    <>
      <Heading
        title="Bubbles"
        subtitle={'@' + (params.username || store.user?.user_name)}
        // subtitleLink={'/user/' + (params.username || store.user?.user_name)}
      />
      <section className={classNames(css.bubbles, !loaded && css._loading)}>
        <header className={css.bubbles_controls}>
          <div className={css.bubbles_controls_sort}>
            <i
              className={classNames('sort', css.bubbles_controls_sort__button, !order && css._desc)}
              onClick={() => setOrder(!order)}
            />
            <select
              onChange={(e) => setSortBy(e.target.value.toLowerCase())}
              value={sortBy.replace(/^(.)/, (match, firstSymbol) => firstSymbol.toUpperCase())}
            >
              <option>Date</option>
              <option>Title</option>
            </select>
          </div>
          <div className={css.bubbles_controls_filter}>
            <i className={'filter'} />
          </div>
          <div className={css.bubbles_controls_view}>
            <span>View:</span>
            <i
              className={classNames('rows', activeView === 'rows' && 'active')}
              onClick={() => setActiveView('rows')}
            />
            <i
              className={classNames('tiles', activeView === 'tiles' && 'active')}
              onClick={() => setActiveView('tiles')}
            />
            <i
              className={classNames('folder', activeView === 'folder' && 'active')}
              onClick={() => setActiveView('folder')}
            />
            <i
              className={classNames('tree', activeView === 'tree' && 'active')}
              onClick={() => setActiveView('tree')}
            />
          </div>
        </header>
        {loaded && (
          <main
            className={classNames(
              css.bubbles__container,
              ['tiles', 'folder'].includes(activeView) && css._2
            )}
          >
            {activeView === 'folder' && (
              <>
                <span className={css.bubbles__counter}>
                  {store.bubbles?.length || 0} bubble{store.bubbles?.length !== 1 && 's'}
                </span>
                {store.bubbles?.map((bubble: any, i: number) => (
                  <BubbleItem bubble={bubble} key={bubble.id} i={i} />
                ))}
              </>
            )}

            {(activeView === 'rows' || activeView === 'tiles') && (
              <>
                <span className={css.bubbles__counter}>
                  {store.links?.length || 0} site{store.links?.length !== 1 && 's'}
                </span>
                {store.links?.map(
                  (link: any, i: any) =>
                    link && (
                      <SiteItem
                        link={link}
                        key={'site_row_card_' + i}
                        row={activeView === 'rows'}
                        i={i}
                      />
                    )
                )}
              </>
            )}

            {activeView === 'tree' && <Tree tree={tree} />}
          </main>
        )}

        {!loaded && <Loader />}
      </section>
    </>
  );
};

export default observer(Index);
