import {useEffect, useState} from "react";
import classNames from "classnames";
import {Tags, Menu} from "bubbles-module";
import css from 'bubbles-module/styles/Bubbles.module.scss'

const Row = ({url, title, tags}: any) => {
  const [image, setImage] = useState(false)

  useEffect(() => {
    try {
      fetch('https://s2.googleusercontent.com/s2/favicons?domain='+ encodeURI(url)+'&sz=64', {
        mode: 'no-cors',
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin':'*'
        }
      })
      setImage(true)
    } catch (e) {
      console.log(e)
      setImage(false)
    }
  }, [url])
  return (
    <div className={css.tree_row}>
      <div
        className={classNames(css.tree_row__picture, !image && css._placeholder)}
        style={{
          backgroundImage: image ? `url(https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=32)` : ''
        }}
      />
      <a href={url} target='_blank' className={css.tree_row__title} title={title} rel="noreferrer">{title}</a>
      <Tags tags={tags}/>
      <div className={css.tree_menu}>
        <Menu>
          <span>Option 1</span>
          <span>Option 2</span>
          <span>Option 3</span>
          <span>Option 4</span>
          <span>Option 5</span>
          <span>Option 6</span>
        </Menu>
      </div>
    </div>
  )
}

const Folder = ({tag, links, defaultOpened = true}: any) => {
  const [isOpened, setIsOpened] = useState(defaultOpened)
  useEffect(() => {
    setIsOpened(defaultOpened)
  }, [defaultOpened])
  return (
    <>
      <div
        className={classNames(css.tree__folder, isOpened && css._opened)}
        onClick={() => setIsOpened(!isOpened)}
      >
        <i className={'folder _alt'}/>
        {tag} <span>({links?.length})</span>
        <i className={classNames('dropdown', isOpened && '_opened')}/>
        <div className={css.tree_menu}>
          <Menu>
            <span>Menu</span>
          </Menu>
        </div>
      </div>
      {isOpened && (
        <div>
          {links?.map(({attributes: {url, title}, bubbles}: any, i: any) => <Row tags={bubbles} url={url} title={title} key={'folder_row_'+i}/>)}
        </div>
      )}
    </>
  )
}

const Tree = ({tree}: any) => {
  const [allOpened, setAllOpened] = useState(true)
  return (
    <>
      <span
        onClick={() => setAllOpened(!allOpened)}
        className={css.tree__toggle}
      >
        {allOpened ? 'Collapse all' : 'Expand all'}
      </span>
      <section className={css.tree}>
        {tree && Object.entries(tree).map(([tag, links], i) => (
          <Folder tag={tag} links={links} key={'folder_'+i} defaultOpened={allOpened}/>
        ))}
      </section>
    </>
  )
}

export default Tree
