import Auth from "./index";
import css from '../../styles/pages/Auth.module.scss'
import {WStore as store} from "bubbles-module";
import {useEffect} from "react";

const AuthSuccess = () => {
  useEffect(() => {
    if (!store?.user)
      window.location.assign('/auth/extension')
  }, []);
  return (
    <Auth>
      <div className={css.success__icon}>
        <i className={'check _32'}/>
      </div>
      <p className={css.success__promotion}>
        Logged in as {store?.user?.user_name}
      </p>
    </Auth>
  )
}

export default AuthSuccess

