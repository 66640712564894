import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import {WStore as store, setUserTg, Button, supabase} from "bubbles-module"
import Auth from "./index";
import css from "../../styles/pages/Auth.module.scss";

const TelegramAuth = () => {
  const [loading, setLoading] = useState(true);
  const [messageSent, setMessageSent] = useState(false);
  useEffect(() => {
    if (store.user && loading) {
      const chat_id = window.location.search.replace("?id=", "");
      setUserTg(store.user.id, chat_id)
        .then(() => {
          const channel = supabase.channel('tg-bot')
          channel.subscribe((status) => {
            if (status === 'SUBSCRIBED' && !messageSent) {
              channel.send({
                type: 'broadcast',
                event: 'auth',
                payload: { chat_id, username: store.user.user_name },
              }).then(() => {
                setMessageSent(true)
                setLoading(false)
              })
            }
          })
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
    }
  }, [store.user]);
  return (
    <Auth>
      {store.user && !loading && (
        <>
          <div className={css.success__icon}>
            <i className={'check _32'}/>
          </div>
          <p>Logged in as {store.user.user_name}</p>
          <a
            className="uiButton"
            href={`https://telegram.me/bubbles_telgram_bot/`}
          >
            Continue to Telegram
          </a>
        </>
      )}
      {store.user && loading && (
        <Button onClick={() => {}} title={'Loading'} icon={'loading'} />
      )}
      {!store.user && (
        <>
          <p>Sign In with GitHub to continue</p>
          <Button
            onClick={() => store.signInWithGithub("auth/tg"+window.location.search)}
            title={"Sign in with GitHub"}
          />
        </>
      )}
    </Auth>
  );
};

export default observer(TelegramAuth);
