import {useEffect, useState} from "react";
import Heading from "../components/Heading";
import {WStore as store, getUsersExceptAuthenticatedUser} from "bubbles-module"
import UserItem from "../components/UserItem";
import css from '../styles/pages/Following.module.scss'

const Users = () => {
  const [loaded, setLoaded] = useState(false)
  const [users, setUsers] = useState([] as any)

  useEffect(() => {
    getUsersExceptAuthenticatedUser(store.user.id).then(res => {
      setUsers(res.data)
      setLoaded(true)
    })
  }, [])

  return (
    <>
      <Heading title={'Users'} subtitle={'@' + store.user?.user_name}/>
      <section className={css.following}>
        {loaded && users?.map((user: any, i: number) => (
          <UserItem user={user} key={'user_'+i}/>
        ))}
      </section>
    </>
  )
}

export default Users
