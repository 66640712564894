import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import './styles/global.scss'
import Layout from "./components/Layout";
import Bubbles from './pages/Bubbles';
import Folder from "./pages/Folder";
import Users from "./pages/Users";
import TelegramAuth from "./pages/Auth/TelegramAuth";
import ExtensionAuth from "./pages/Auth/ExtensionAuth";
import AuthSuccess from "./pages/Auth/AuthSuccess";
import {WStore as store, Notification, Popup} from "bubbles-module"
import {observer} from "mobx-react";

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/bubbles" replace={true} />} />
          <Route path='auth/tg' element={<TelegramAuth />}/>
          <Route path='auth/extension' element={<ExtensionAuth />}/>
          <Route path='auth/extension/success' element={<AuthSuccess />}/>
          {/*<Route path='home' element={<Home/>}/>*/}
          <Route path='bubbles' element={<Bubbles/>}/>
          <Route path='bubbles/:bubbletag/' element={<Folder/>}/>
          <Route path='user/:username/bubbles' element={<Bubbles />}/>
          <Route path='user/:username/bubbles/:bubbletag/' element={<Folder/>}/>
          <Route path='users' element={<Users/>}/>
          {/*<Route path='user' element={<User/>}/>*/}
          {/*<Route path='user/:username' element={<User/>}/>*/}
        </Routes>
      </Layout>
      {!!store.notification && <Notification {...store.notification} />}
      {!!store.popup && <Popup {...store.popup} />}
    </>
  );
}

export default observer(App);
