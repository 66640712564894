import css from '../styles/components/UserItem.module.scss'
import classNames from "classnames";
import {Menu} from "bubbles-module";
import {NavLink} from "react-router-dom";

const UserItem = ({user}: any) => {
  const {
    avatar_url,
    name,
    user_name,
    bubbles
  } = user
  return (
    <NavLink to={'/user/'+user_name+'/bubbles'}>
      <div className={css.user}>
        <div
          className={classNames(css.user__avatar, !avatar_url && css._placeholder)}
          style={{backgroundImage: `url(${avatar_url})`}}
        />
        <div className={css.user__info}>
          <p className={css.user__username}>{name || user_name}</p>
          <p className={css.user__stats}><i className={'bubbles _18'}/> {bubbles}</p>
        </div>
        <Menu style={{marginTop: '-6px'}}/>
      </div>
    </NavLink>
  );
};

export default UserItem;
