import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { WStore as store, Button } from 'bubbles-module';
import Auth from './index';

const ExtensionAuth = () => {
  const session = useObserver(() => store.session);

  useEffect(() => {
    if (session)
      setTimeout(() => {
        window.location.assign('/auth/extension/success')
      }, 1500);
  }, [session]);

  return (
    <Auth>
      {store.user && (
        <>
          <p>Signing you in...</p>
        </>
      )}
      {!store.user && (
        <>
          <p>Sign In with GitHub to continue</p>
          <Button
            onClick={() => store.signInWithGithub('auth/extension')}
            title={'Sign in with GitHub'}
          />
        </>
      )}
    </Auth>
  );
};

export default ExtensionAuth;
