import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Heading from "../components/Heading";
import classNames from "classnames";
import {WStore as store, Loader, SiteItem, Button, getBubbleLinks, getUser} from "bubbles-module"
import css from 'bubbles-module/styles/Bubbles.module.scss'

const Folder = () => {
  const params = useParams()
  const [user, setUser] = useState(null as any);
  const [loaded, setLoaded] = useState(false)
  const [counter, setCounter] = useState(0)
  // const [isSubscribed, setIsSubscribed] = useState(false)
  // const [loadingButton, setLoadingButton] = useState(false)

  useEffect(() => {
    if(params.userId){
      getUser(params.userId).then(res => {
        setUser(res)
      })
    } else {
      setUser(store.user)
    }
  }, [params?.userId])

  useEffect(() => {
    setLoaded(false)
    const tag = params.bubbletag;
    const username = params.username;
    if (!tag || !username) {
      console.log('error', 'No tag or username');
      return;
    }
    getBubbleLinks(tag, username).then(({ data, error }: any) => {
      if (error) {
        console.log('error', error);
        store.showNotification({
          message: 'Unexpected error occurred!',
          type: 'error',
        });
        return;
      }
      if (data) {
        data.forEach((link: any) =>
            link.bubbles.forEach((bubble: any) => (bubble.creator = params.username))
        );
      }
      store.setLinks(data);
      setLoaded(true);
    });
  }, [params.bubbletag, params.username])

  return (
    <>
      <Heading title='Bubbles' subtitle={'@' + (store.links?.[0]?.creator?.user_name || params.username)} subtitleLink={'/user/'+(store.links?.[0]?.creator?.user_name || params.username)+'/bubbles/'}>
        <div className={css.folder__head}>
          <i className={'arrow'} onClick={() => window.history.back()}/>
          <span>{params.bubbletag}</span>
          {/*{(user?.id !== store.user.id) && (*/}
          {/*  <Button*/}
          {/*    title={*/}
          {/*      !loadingButton*/}
          {/*        ? (isSubscribed ? 'Following' : 'Follow')*/}
          {/*        : ''*/}
          {/*    }*/}
          {/*    icon={loadingButton ? 'loading' : (isSubscribed ? 'check' : '')}*/}
          {/*    className={isSubscribed ? 'active' : ''}*/}
          {/*    onClick={async (e: any) => {*/}
          {/*      e.preventDefault()*/}
          {/*      e.stopPropagation()*/}
          {/*      // setLoadingButton(true)*/}
          {/*      // if(isSubscribed){*/}
          {/*      //   await unfollowBubble(params.id)*/}
          {/*      // } else {*/}
          {/*      //   await followBubble(params.id)*/}
          {/*      // }*/}
          {/*      // setIsSubscribed(!isSubscribed)*/}
          {/*      // setLoadingButton(false)*/}
          {/*    }}*/}
          {/*    disabled={loadingButton}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </Heading>
      {loaded && (
        <main className={css.bubbles__container}>
          <span className={css.bubbles__counter}>{store.links?.length || 0} site{store.links?.length !== 1 && 's'}</span>
          {store.links?.map((link: any, i: any) => (
            <SiteItem link={link} key={'site_row_card_'+i} row i={i}/>
          ))}
          {/*{counter > (store.links || [])?.length && (*/}
          {/*  <Button*/}
          {/*    className={css.bubbles__load}*/}
          {/*    title={loadingPage ? 'Loading' : 'Load more'}*/}
          {/*    icon={loadingPage ? 'loading' : ''}*/}
          {/*    onClick={loadMore}*/}
          {/*  />*/}
          {/*)}*/}
        </main>
      )}
      {!loaded && <Loader />}
    </>
  )
}

export default Folder
