import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

export interface Props {
 title: string;
 subtitle?: any;
 subtitleLink?: any;
 children?: JSX.Element;
 className?: any;
}

const Heading = ({
 title,
 subtitle,
 subtitleLink,
 children,
 className = '',
}: Props) => {
 useEffect(() => {
  document.title = (subtitle.replace('@', '') || title) + ' | Bubbles';
 }, [subtitle, title]);
 return (
  <section
   className={classNames('uiHeading', className)}
  >
   <section className="uiHeading__wrapper">
    <h1 className="uiHeading__title">{title}</h1>
    {subtitle && !subtitleLink && (
     <span className="uiHeading__subtitle">
      {subtitle}
     </span>
    )}
    {subtitle && subtitleLink && (
     <NavLink to={subtitleLink}>
      <span className="uiHeading__subtitle">
       {subtitle}
      </span>
     </NavLink>
    )}
   </section>
   {children}
  </section>
 );
};

export default Heading;
