import React from 'react';
import {NavLink} from 'react-router-dom';
import {WStore as store, Menu} from "bubbles-module";
import {observer} from "mobx-react";

const Header = () => {
  return (
    <header className='uiHeader'>
      <a href='/bubbles'>
        <div className='uiLogo'/>
      </a>
      <nav className='uiHeader__links'>
        {/*<NavLink to='/home' className='uiHeader_link _home'>*/}
        {/*  <i className='feed _40'/>*/}
        {/*  <span>Home</span>*/}
        {/*</NavLink>*/}
        {store.user && (
          <>
            <NavLink to='/bubbles' className='uiHeader_link _bubbles'>
              <i className='bookmark _40'/>
              <span>Bubbles</span>
            </NavLink>
            <NavLink to='/users' className='uiHeader_link _following'>
              <i className='users _40'/>
              <span>Users</span>
            </NavLink>
          </>
        )}
      </nav>
      {store.user && (
        <section className='uiHeader_user'>
          <NavLink to={'/user'} style={{display: 'contents'}}>
            <div className='uiHeader_user__avatar' style={{backgroundImage: `url(${store.user.avatar_url})`}}/>
            <div className='uiHeader_user__info'>
              <p className='uiHeader_user__username' title={store.user.full_name}>{store.user.full_name}</p>
              <span className='uiHeader_user__login' title={store.user.user_name}>@{store.user.user_name}</span>
            </div>
          </NavLink>
          <Menu style={{position: 'relative'}}>
            <NavLink to='/settings' className={'uiHeader_user_menu__option'}>
              <i className={'settings _18'} />Settings
            </NavLink>
            <div
              className={'uiHeader_user_menu__option'}
              onClick={store.signOut}
            >
              <i className={'signOut _18'} />
              Logout
            </div>
          </Menu>
        </section>
      )}
    </header>
  )
}

export default observer(Header)
