import React, {useEffect} from 'react'
import Header from "./Header";
import {useNavigate} from "react-router-dom";
import {WStore as store} from "bubbles-module"
import {observer} from "mobx-react";

export interface Props {
  children: JSX.Element | JSX.Element[] | string;
}

const Layout = ({children}: Props) => {
  const navigate = useNavigate()
  useEffect(() => {
    const hasToRedirectHome = !store.user && !window.location.pathname.includes('/auth');
    if (hasToRedirectHome) navigate('/bubbles')
  }, [store.user])
  return (
    <>
      {!window.location.pathname.includes('/auth') && (
        <>
          <section className='uiContainer'>
            <Header/>
            <main className='uiMain'>
              {children}
            </main>
          </section>
          {!store.user && (
            <section className='uiNoUser'>
              <p>Sign Up with GitHub to have access!</p>
              <button
                className='alt'
                onClick={store.signInWithGithub}
              >
                Sign Up
              </button>
            </section>
          )}
        </>
      )}
      {window.location.pathname.includes('/auth') && children}
    </>
  )
}

export default observer(Layout)
