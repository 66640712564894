"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const supabase_js_1 = require("@supabase/supabase-js");
const options = {};
if (typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined') {
    const chromeStorageInterface = {
        async getItem(key) {
            const storage = await chrome.storage.local.get(key);
            return storage?.[key];
        },
        async setItem(key, value) {
            await chrome.storage.local.set({
                [key]: JSON.parse(value)
            });
        },
        async removeItem(key) {
            await chrome.storage.local.remove(key);
        }
    };
    options.auth = { storage: chromeStorageInterface };
}
// client initialization
const supabaseUrl = "https://pnkqeroqzeghvurgtpik.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBua3Flcm9xemVnaHZ1cmd0cGlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA0NDI4MDksImV4cCI6MTk4NjAxODgwOX0.4NLAkCirFUN4VTB8CqRlZe4iAPDQn0zUtSbutfeJCyw";
const supabase = (0, supabase_js_1.createClient)(supabaseUrl, supabaseKey, options);
exports.default = supabase;
