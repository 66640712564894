"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const mobx_persist_store_1 = require("mobx-persist-store");
const default_1 = require("./default");
const chromeStorageInterface = {
    async getItem(key) {
        if (!chrome.storage)
            return null;
        const { [key]: value } = await chrome.storage.local.get(key);
        return value;
    },
    async setItem(key, value) {
        if (!chrome.storage)
            return;
        await chrome.storage.local.set({ [key]: JSON.parse(value) });
    },
    async removeItem(key) {
        if (!chrome.storage)
            return;
        await chrome.storage.local.remove(key);
    }
};
class ExtensionStore extends default_1.Store {
    userAgent = 'unknown';
    preferredView = 'links';
    whiteList = [];
    constructor() {
        super();
        (0, mobx_1.makeObservable)(this, {
            userAgent: mobx_1.observable,
            preferredView: mobx_1.observable,
        });
        (0, mobx_persist_store_1.makePersistable)(this, {
            name: 'appStore',
            properties: [
                'links',
                'bubbles',
                'preferredView',
            ],
            storage: chromeStorageInterface,
        }).then(r => console.log('Extension store persisted:', r));
    }
    getBrowser() {
        if (typeof window === 'undefined')
            return 'unknown';
        const userAgent = window.navigator.userAgent;
        let browser = 'unknown';
        // Check for Chrome first, since Chrome's userAgent string also contains 'Safari'
        if (userAgent.indexOf('Chrome') !== -1) {
            browser = 'Chrome';
        }
        // Check for Safari
        else if (userAgent.indexOf('Safari') !== -1) {
            browser = 'Safari';
        }
        return browser;
    }
    setUserAgent = () => {
        this.userAgent = this.getBrowser();
    };
    setPreferredView = (view) => {
        this.preferredView = view;
    };
}
exports.default = new ExtensionStore();
